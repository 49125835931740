
import './home.css';
import React, { useEffect, useState } from 'react';
import Aos from 'aos'
import 'aos/dist/aos.css'
import video from '../../Assets/s.png'

const Home = () => {
  const [formData, setFormData] = useState({ firstName: '', lastName: '' });
  const [submitted, setSubmitted] = useState(false); // Form gönderildi mi?
  const [kullanicilar, setUsers] = useState([]); // Kullanıcı listesi için state
  const [loading, setLoading] = useState(true);

 

  //Bileşen yüklendiğinde kullanıcı listesini getir
  useEffect(() => {
    Aos.init({duration:2000})
    fetch('http://localhost:5500/kullaniciListesi') // Node.js sunucusuna GET isteği
      .then((response) => response.json()) // JSON yanıtı al
      .then((data) => {
        setUsers(data); // Veriyi state ile eşitle
        setLoading(false); // Yüklenme durumu sona erer
      })
      .catch((error) => {
        console.error('Hata:', error); // Hataları konsola yazdır
        setLoading(false); // Hata durumunda yüklenme durumu sona erer
      });
  }, []); // Sadece bileşen yüklendiğinde çalışır

  // Form alanlarında değişiklik olduğunda state güncelleme
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      formData, // Var olan verileri koru
      [name]: value,
    });
  };

  // Form gönderildiğinde POST isteği ile kullanıcı ekleme
  const handleSubmitForm = (e) => {
    e.preventDefault(); // Sayfanın yeniden yüklenmesini önler

    fetch('http://localhost:5500/kullaniciEkle', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData), // Form verilerini JSON olarak gönder
    })
      .then((res) => res.json()) // JSON yanıtı alın
      .then((result) => {
        console.log(result);
        // Mevcut kullanıcı listesine yeni kullanıcıyı ekle
        setUsers((prevUsers) => [
          prevUsers,
          { id: result.SONID, formData },
        ]);
        setFormData({ firstName: '', lastName: '' }); // Formu temizle
      })

      .catch((error) => console.error('Hata:', error)); // Hataları yakala
  };

  window.onload = function() {
    var modal = document.getElementById("myModal");
    var span = document.getElementsByClassName("close")[0];

    // Modalı göster
    modal.style.display = "block";

    // Kapat düğmesine tıklayınca modalı kapat
    span.onclick = function() {
        modal.style.display = "none";
    };

    // Kullanıcı modal dışına tıklarsa modalı kapat
    window.onclick = function(event) {
        if (event.target == modal) {
            modal.style.display = "none";
        }
    };
};
 
  
  return (
   <section className='home'>
      
     <div className="secContainer container">
    
      <div className="homeText">
     
       <h1 data-aos="fade-up"className="title">
        Sevgi Varsa Engel Yoktur
       </h1>
       <p data-aos="fade-up" data-aos-duration="2500" className="subTitle">
        Engelli Aileleri İle Dayanışma Derneği
       </p>

       {/* <button type="button"  className='btn'>
        <a href="#">Bağış Yap</a>
       </button> */}

      
      </div>
      <div id="myModal" class="modal">
    <div class="modal-content">
        <span class="close">&times;</span>
        <p>Değerli üyelerimiz,</p>
        <p>28 Eylül Cumartesi günü saat 15:30'da dernek merkezimizde yapılacak olağan genel kurulumuza katılım yapmanızı rica ederiz.</p>
    </div>
</div>



      {/*<div className="homeCard grid">
             <h1> Proje Ara </h1>
      <form onSubmit={handleSubmitForm}> 
        <div data-aos="fade-right" data-aos-duration="2000" className="distDiv">
          {/* <label htmlFor="firstName">Proje Adı</label> 
          <input type="text" id="firstName"  name="firstName"
          onChange={handleInputChange}  value={formData.firstName} placeholder='Proje Adı Yazınız..'/>
          <button data-aos="fade-right" type="submit" className='btn' > Ara</button>
        </div>*/}

        {/* <div className="locationDiv">
          <label htmlFor="lastName">Soyad</label>
          <input type="text"  id="lastName" name="lastName"
           onChange={handleInputChange} value={formData.lastName}  placeholder='Soyadınız..'/>
         
        </div> 
       
</form>*/}
{/* {submitted && (
        <div>
          <h2>Teşekkürler, formunuz gönderildi!</h2>
          <p>Girdiğiniz veriler:</p>
          <pre>{JSON.stringify(formData, null, 2)}</pre>
        </div>
      )} */}

        {/* <div> 
      <h1>Kullanıcı Listesi</h1>
      {loading ? ( 
        <p>Yükleniyor...</p> // Yüklenme sırasında mesaj göster
      ) : (
        kullanicilar.length === 0 ? ( 
          <p>Kullanıcı bulunamadı</p> // Boş liste durumu
        ) : (
          <ul>
            {kullanicilar.map((user) => (
              <li key={user.id}>
                {user.firstName} {user.lastName}
              </li>
            ))}
          </ul>
        )
      )}
    </div>



      </div> */}
      
     </div>  
    
 
   </section>
    )
}

export default Home